<template>
  <div class="w-full h-full pt-5">
    <div class="flex">
      <h1 class="text-xl text-left font-extrabold pl-4">Organisation</h1>
      <div class="w-7/12 mt-1 ml-4">
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </div>

    <div class="mt-6 mx-3">
      <div class="mx-3">
        <Tabs
          class="mt-6 mx-4"
          :tabs="tabsItems"
          :active-tab="activeTab"
          @currentTab="activeTab = $event"
          :border="true"
        />
      </div>
      <JobLevel v-if="activeTab === 'Job Level'" />
      <JobGrade v-if="activeTab === 'Job Grade'" />
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import Tabs from "@scelloo/cloudenly-ui/src/components/tab";
import JobLevel from "./JobLevel";
import JobGrade from "./JobGrade";

export default {
  name: "Designation",
  components: {
    Breadcrumb,
    JobLevel,
    JobGrade,
    Tabs,
  },
  data() {
    return {
      tabsItems: ["Job Level", "Job Grade"],
      activeTab: "Job Level",
      breadcrumbs: [
        {
          disabled: false,
          text: "Organisation",
          id: "",
        },
        {
          disabled: false,
          text: "Job Levels",
          id: "Job Levels",
        },
      ],
    };
  },
};
</script>
