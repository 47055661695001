<template>
  <div>
    <div class="mx-3 mt-6">
      <Card class="mt-6 p-5">
        <div class="flex mb-6" style="justify-content: flex-end">
          <Button
            class="text-white"
            @click="openModal = true"
            :background="appearance.buttonColor || defaultBTNColor"
          >
            New Job Grade
          </Button>
        </div>
        <template v-slot:footer>
          <card-footer printcard reloadcard />
        </template>
      </Card>
    </div>
    <div class="p-3">
      <sTable
        :headers="gradeHeader"
        :items="items"
        :loading="loadingData"
        aria-label="leveltable"
        id="printMe"
        style="width: 100%"
        class="w-full"
        has-number
      >
        <template v-slot:item="{ item }">
          <span v-if="item.name" class="py-5">{{ item.data.name }}</span>
          <span v-if="item.description" class="py-5">{{
            item.data.description
          }}</span>
          <span v-else-if="item.id">
            <Menu left top="-150" margin="110" class="my-2 p-0">
              <template v-slot:title>
                <icon icon-name="more_icon" size="xs" />
              </template>
              <div style="width: 116px; height: 81px" class="py-3 px-2">
                <div
                  class="flex py-1 px-3 more h-8 cursor-pointer"
                  @click="handleEdit(item.data)"
                >
                  <icon
                    icon-name="edit"
                    class-name="text-blueCrayola mr-2"
                    size="xsm"
                  />
                  <p class="pt-1">Edit</p>
                </div>
                <div
                  class="flex px-3 py-1 more h-8 cursor-pointer"
                  @click="confirmDelete(item.data.id)"
                >
                  <icon
                    icon-name="delete"
                    class-name="text-desire mr-2"
                    size="xsm"
                  />
                  <p class="pt-1">Delete</p>
                </div>
              </div>
            </Menu>
          </span>
        </template>
      </sTable>
    </div>

    <validation-observer
      ref="observer"
      rules="required"
      v-slot="{ handleSubmit }"
    >
      <RightSideBar
        v-if="openModal"
        @submit="handleSubmit(createJobGrade)"
        @close="openModal = false"
        submit="Save"
        :loading="loading"
        :button-class="`bg-dynamicBackBtn text-white`"
        :disabled-button="disableBtn"
      >
        <template v-slot:title>
          <p class="text-darkPurple">Add New Job Grade</p>
        </template>
        <template v-slot:subtitle>
          <p class="font-semibold text-darkPurple">
            Which of these best describe the Job Grade?
          </p>
        </template>

        <div
          class="grid grid-cols-12 gap-8 text-darkPurple"
          style="margin-top: 1.5rem"
        >
          <div class="col-span-12">
            <div class="text-sm flex gap-2">
              <p class="text-base w-auto">Job grade</p>
              <Icon
                v-tooltip.top-start="`Max limit of 3 characters`"
                icon-name="icon-info"
                size="xms"
              />
            </div>
            <c-text
              placeholder="--Enter Job Grade--"
              variant="w-full h-10"
              style="height: 40px !important"
              v-model="payload.name"
              :rules="['required']"
            />
          </div>

          <div class="col-span-12 mt-3">
            <div class="text-sm flex gap-2">
              <p class="text-base w-auto">Job Grade Description</p>
            </div>
            <c-textarea v-model="payload.description" :rules="['required']" />
          </div>
        </div>
      </RightSideBar>
    </validation-observer>

    <Modal v-if="dialogDel">
      <Card class="p-5 flex flex-col max-w-md">
        <div class>
          <p class="font-bold ml-4 my-5 text-center">
            Are you sure you want to delete this Job Grade?
          </p>
          <div class="flex">
            <Button
              class="text-white"
              @click="deleteJobGrade"
              :background="appearance.buttonColor || defaultBTNColor"
            >
              Yes
            </Button>
            <Button
              background-color="none"
              class="text-dynamicBackBtn ml-4 border border-solid border-dynamicBackBtn"
              @click="dialogDel = false"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Card>
    </Modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { ValidationObserver } from "vee-validate";
import STable from "@scelloo/cloudenly-ui/src/components/table";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import CTextarea from "@scelloo/cloudenly-ui/src/components/text-area";
import CardFooter from "@/components/CardFooter";
import Card from "@/components/Card";
import Button from "@/components/Button";
import RightSideBar from "@/components/RightSideBar";
import Menu from "@/components/Menu";
import Modal from "@/components/Modal";

export default {
  name: "JobGrade",
  components: {
    Card,
    Modal,
    Menu,
    CardFooter,
    RightSideBar,
    CTextarea,
    ValidationObserver,
    CText,
    Button,
    Card,
    STable,
  },
  computed: {
    ...mapState({
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
  },
  data() {
    return {
      loadingData: true,
      gradeHeader: [
        { title: "Grades", value: "name" },
        { title: "Job Grade Description", value: "description" },
        { title: "", value: "id", image: true },
      ],
      metaData: {},
      dialogDel: false,
      loading: false,
      disableBtn: false,
      openModal: false,
      items: [],
      payload: {
        name: "",
        description: "",
      },
      gradeId: null,
    };
  },

  methods: {
    createJobGrade() {
      if (this.payload.name.length > 3) {
        this.$toasted.error("Maximum of 3 characters needed for Job Grade", {
          duration: 5000,
        });
      } else {
        if (this.gradeId) {
          this.$_editJobGrade(this.gradeId, this.payload)
            .then((response) => {
              this.$toasted.success(response.data.message, { duration: 5000 });
              this.openModal = false;
              this.gradeId = null;
              this.payload.name = "";
              this.payload.description = "";
              this.getJobGrades();
            })
            .catch((error) => {
              this.$toasted.error(error.response.data.message, {
                duration: 5000,
              });
            });
        } else {
          this.$_createJobGrade(this.payload)
            .then((response) => {
              this.$toasted.success(response.data.message, { duration: 5000 });
              this.openModal = false;
              this.getJobGrades();
            })
            .catch((error) => {
              this.$toasted.error(error.response.data.message, {
                duration: 5000,
              });
            });
        }
      }
    },

    confirmDelete(id) {
      this.gradeId = id;
      this.dialogDel = true;
    },

    handleEdit(data) {
      this.payload.name = data.name;
      this.payload.description = data.description;
      this.gradeId = data.id;
      this.openModal = true;
    },

    deleteJobGrade() {
      this.$_deleteJobGrade(this.gradeId)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.dialogDel = false;
          this.gradeId = null;
          this.getJobGrades();
        })
        .catch((error) => {
          this.$toasted.error(error.response.data.message, {
            duration: 5000,
          });
        });
    },

    getJobGrades() {
      this.$_getJobGrade().then((response) => {
        this.items = response.data.grades;
        this.loadingData = false;
      });
    },
  },

  mounted() {
    this.getJobGrades();
  },
};
</script>
